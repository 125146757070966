<template>
  <validation-observer ref="form" novalidate>
    <div class="row" style="height: 100px;">
      <div class="col-md-12"><label class="ml-4 bold-left">{{ $t('time') }}</label>: <label>{{ formatTime(audioDuration) }} </label></div>
    </div>
    <div class="row" style="height: 150px;">
      <loading :active="loading" color="#F37E00" :is-full-page="false" />
      <audio style="display: none" ref="audioPlayer" :src="audioSrc" @loadedmetadata="onLoadedMetadata" @timeupdate="onTimeUpdate" @error="onAudioError" controls></audio>
      <div class="col-md-6 center">
        <div>{{ formatTime(currentTime) }}</div>
      </div>
      <div class="col-md-6 center">
        <div>{{ formatTime(audioDuration) }}</div>
      </div>
      <div class="col-md-12 center">
        <input style="width: 300px" type="range" min="0" :max="audioDuration" step="0.1" v-model="currentTime" @input="onSliderChange" />
      </div>

      <div class="col-md-12 center">
        <div>
          <button class="btn" @click="rewind"><img class="rewind-button" src="@/assets/rewind_button.png" alt="Rewind" /></button>
          <button class="btn" @click="togglePlayPause"><img class="play-button" :src="isPlaying ? require('@/assets/pause_button.png') : require('@/assets/play_button.png')" :alt="isPlaying ? 'Pausa' : 'Play'" /></button>
          <button class="btn" @click="forward"><img class="forward-button" src="@/assets/forward_button.png" alt="Rewind" /></button>
        </div>
      </div>
      <div class="col-md-12 center">
        <label v-if="error" class="bold">{{ error }}</label>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import ConfirmationOptions from '@/constants/ConfirmationOptions';
import { mapGetters } from 'vuex';
import { modulesName } from '@/store';
import {
  getCallRecording as _getCallRecording,
} from '@/services/WaitingRoomService';

export default {
  name: 'PlayRecording',
  components: {
  },
  props: {
    waitingRoomId: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      collections: {
        confirmations: ConfirmationOptions,
        supervisors: [],
        
      },
      model: {
        evaluatorId: null,
        turnNumber: null,
        comment: null,
        referralId: null,
        fullComment: null,
        needSecondEvaluation: false
      },
      canResetService: false,
      loading: false,
      canEditEvaluation: false,
      audioSrc: null,
      audioDuration: 0,
      currentTime: 0,
      isPlaying: false,
      error: null,
    };
  },
  async mounted() {
    
    this.$refs.form.reset();
    await this.loadRecording();
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation', 'fullProfile']),
  },
  watch: {
    
  },
  methods: {
    async loadRecording() {
      await _getCallRecording(this.waitingRoomId)
        .then(({ data }) => {
          this.audioSrc=data;          
        });
    },
    onLoadedMetadata() {
        this.audioDuration = this.$refs.audioPlayer.duration;
      },
    onTimeUpdate() {
        this.currentTime = this.$refs.audioPlayer.currentTime;
      },
      async togglePlayPause() {
        const audio = this.$refs.audioPlayer;
      if (audio.paused) {
        await audio.play();
        this.isPlaying = true;
      } else {
        audio.pause();
        this.isPlaying = false;
      }
      },
      rewind() {
        const audio = this.$refs.audioPlayer;
        audio.currentTime = Math.max(audio.currentTime - 10, 0);
      },
      forward() {
        const audio = this.$refs.audioPlayer;
        audio.currentTime = Math.min(audio.currentTime + 10, this.audioDuration);
      },
      onSliderChange(event) {
        this.$refs.audioPlayer.currentTime = event.target.value;
      },
      formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
      },
      onAudioError() {
        this.error = this.$t('recordingNotLoaded');
      },
      resetPlayer() {
        this.currentTime = 0;
        this.isPlaying = false;
        this.$refs.audioPlayer.load();
      },
  },
};
</script>
<style>
.bold {
  font-weight: bold;
}
.bold-left {
  float: left;
  font-weight: bold;
}
.center {
  text-align: center;
}

.play-button {
  height: 80px;
}

.rewind-button {
  height: 40px;
}

.forward-button {
  height: 40px;
}

input[type="range"] {
  width: 100%;
  -webkit-appearance: none; /* Quita el estilo predeterminado de los navegadores Webkit */
}
input[type="range"]::-webkit-slider-runnable-track {
  height: 5px;
  background: lightgray;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background: #FF8105;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -5px; /* Compensa el deslizamiento para que esté centrado en la pista */
}
input[type="range"]::-moz-range-track {
  height: 5px;
  background: #FF8105;
}
input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #FF8105;
  border-radius: 50%;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  height: 5px;
  background: #FF8105;
  border-color: transparent; /* Oculta la pista original en IE */
  color: transparent; /* Oculta la pista original en IE */
}
input[type="range"]::-ms-thumb {
  width: 15px;
  height: 15px;
  background: #FF8105;
  border-radius: 50%;
  cursor: pointer;
}
</style>