<template>
  <div class="row">
    <div class="col-md-12">
      <div
        class="p-3 card search-result lightgreen"
        :class="{
          expand: expand,
        }"
      >
        <span class="button-expand" @click="expand = !expand">
          <i
            class="fal"
            :class="{
              'fa-chevron-circle-right': !expand,
              'fa-chevron-circle-down': expand,
            }"
          ></i>
        </span>

        <div class="row">
          <div class="col-md-6 col-xl-3">
            <div class="cell">
              <label>{{ $t('status') }}:</label>
              <span>{{ status }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('minutes') }}: </label>
              <span class="minute-box green">
                {{ value.minutesAttending || 0 }}
              </span>
            </div>

            <div class="cell">
              <label>{{ $t('active') }}:</label>
              <span>{{ activeParticipant?.fullName || ' -' }}</span>
            </div>
            <div class="cell">
              <label>{{ specialFieldConfiguration.specialField3Name }}:</label>
              <span>{{ activeSpecialField3 || ' -' }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('passive') }}:</label>
              <span>{{ passiveParticipant?.fullName || ' -' }}</span>
            </div>
            <div class="cell">
              <label>{{ specialFieldConfiguration.specialField3Name }}:</label>
              <span>{{ passiveSpecialField3|| ' -' }}</span>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="cell">
              <label>{{ $t('citizen') }}:</label>
              <span>{{ value.clientName || ' -' }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('turnNumber') }}: </label>
              <span>{{ turnNumber }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('row') }}: </label>
              <span>{{ value.serviceQueueName || '-' }}</span>
            </div>

            <div class="cell">
              <label>{{ $t('service') }}: </label>
              <span>{{ serviceTypeNames }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('turnMinutes') }}: </label>
              <span
                v-if="value.turnMinutesAttending != null"
                class="minute-box green"
              >
                {{ value.turnMinutesAttending }}
              </span>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="cell">
              <label>{{ $t('ring') }}: </label>
              <span>{{ $t(value.isCall ? 'yes' : 'no') }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('cellPhone') }}: </label>
              <span>{{ value.phoneNumber | telephone }}</span>
            </div>
            <div class="cell">
              <label>Hold: </label>
              <span>{{ $t(value.isOnHold ? 'yes' : 'no') }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('holdTime') }}: </label>
              <span class="minute-box green">{{ value.timeInHold || 0 }}</span>
            </div>
            <div class="cell">
              <label style="max-width: 200px;">{{ $t('communicationCompletedByCitizen') }}: </label>
              <span v-if="value.isCitizenActive"> {{ $t(value.completedByCitizen ? 'yes' : 'no') }}</span>
              <span v-else> - </span>
            </div>
            <div class="cell">
              <label style="max-width: 200px;">{{ $t('communicationCompletionDate') }}: </label>
              <span v-if="value.isCitizenActive"> {{ value.completedDate | date }}</span>
              <span v-else> - </span>
            </div>
          </div>
          <div class="col-md-6 col-xl-3">
            <div class="cell">
              <label>{{ $t('chat') }}: </label>
              <span>{{ $t(value.isChat ? 'yes' : 'no') }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('language') }}: </label>
              <span>{{ $t(value.language || '-') }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('supervisor') }}: </label>
              <span>{{ supervisorParticipant?.fullName || '-' }}</span>
            </div>
            <div class="cell">
              <label>{{ $t('monitor') }}: </label>
              <span>{{ monitorParticipant?.fullName || '-' }}</span>
            </div>
          </div>
          <div class="action-options">
            <b-dropdown variant="primary" right>
              <template #button-content>
                <i class="far fa-cog" />
              </template>
              <span v-for="(action, i) in actions" :key="i">
                <b-dropdown-item @click="() => action.action(value)">
                  <i
                    class="mr-1"
                    :class="action.iconClass"
                    :style="{ color: action.iconColor }"
                  ></i>
                  {{ action.label }}
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
              </span>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div v-if="expand" class="col-md-12 mt-1">
      <div class="p-2 card search-result expanded-lightgreen">
        <div class="box p-3">
          <div class="row">
            <div class="col-md-12">
              <div class="title mb-2">{{ $t('participants') }}</div>
            </div>
            <div class="col-md-12 mb-3">
              <div class="row">
                <div class="col-md-6 col-xl-4">
                  <div class="subtitle">
                    <label>{{ $t('name') }} </label>
                    <hr class="my-0" />
                  </div>
                </div>
                <div class="col-md-6 col-xl-4">
                  <div class="subtitle">
                    <label>{{ $t('role') }} </label>
                    <hr class="my-0" />
                  </div>
                </div>
                <div class="col-md-6 col-xl-2">
                  <div class="subtitle">
                    <label>{{ $t('active') }} </label>
                    <hr class="my-0" />
                  </div>
                </div>
                <div class="col-md-6 col-xl-2">
                  <div class="subtitle">
                    <label>{{ $t('time') }} ({{ $t('minutes') }})</label>
                    <hr class="my-0" />
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(participant, index) in value.participants"
              :key="index"
              class="col-md-12"
            >
              <div class="row">
                <div class="col-md-6 col-xl-4">
                  {{ participant.fullName }}
                </div>
                <div class="col-md-6 col-xl-4">
                  {{ getParticipantRole(participant.participantType) }}
                </div>
                <div class="col-md-6 col-xl-2">
                  {{ $t(participant.isActive ? 'yes' : 'no') }}
                </div>
                <div class="col-md-6 col-xl-2">
                  <span class="minute-box green">
                    {{ participant.minutesAttending }}
                  </span>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParticipantType from '@/constants/ParticipantType';
import WaitingRoomStatus from '@/constants/status/WaitingRoomStatus';
import { addToWaitingRoom as _addToWaitingRoom } from '@/services/WaitingRoomService';
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';

export default {
  name: 'MonitorSearchResult',
  components: {},
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    specialFieldConfiguration: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('getInto'),
          iconClass: 'fas fa-desktop',
          iconColor: '#399C21',
          action: this.getInto,
        },
      ],
      expand: false,
    };
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['profile']),
    turnNumber() {
      return this.value.turnNumber
        ? `${this.value.serviceQueueStartingLetter || ''}${
            this.value.turnNumber
          }`
        : '-';
    },
    serviceTypeNames() {
      return this.value.serviceTypeNames.length
        ? this.value.serviceTypeNames.join(', ')
        : '-';
    },
    activeParticipant() {
      return this.value.participants.find(
        (x) => x.participantType == ParticipantType.Active && x.isActive,
      );
    },
    passiveParticipant() {
      return this.value.participants.find(
        (x) => x.participantType == ParticipantType.Passive && x.isActive,
      );
    },
    monitorParticipant() {
      return this.value.participants.find(
        (x) => x.participantType == ParticipantType.Monitor && x.isActive,
      );
    },
    supervisorParticipant() {
      return this.value.participants.find(
        (x) => x.participantType == ParticipantType.Supervisor && x.isActive,
      );
    },
    status() {
      let result = '';
      switch (this.value.waitingRoomStatus) {
        case WaitingRoomStatus.Created:
          result = this.$t('created');
          break;
        case WaitingRoomStatus.WaitingPassive:
          result = this.$t('waitingPassive');
          break;
        case WaitingRoomStatus.WaitingCitizen:
          result = this.$t('waitingCitizen');
          break;
        case WaitingRoomStatus.SessionInProgress:
          result = this.$t('inProgress');
          break;
      }

      return result;
    },
    activeSpecialField3() {
      return this.specialFieldConfiguration.specialField3Options
        ?.filter((x) => this.activeParticipant.specialField3Ids.find((m) => m == x.id))
        .map((x) => x.name)
        .join(', ');
    },
    passiveSpecialField3() {
      return this.specialFieldConfiguration.specialField3Options
        ?.filter((x) => this.passiveParticipant?.specialField3Ids.find((m) => m == x.id))
        .map((x) => x.name)
        .join(', ');
    },
  },
  methods: {
    getParticipantRole(participantType) {
      let result = '';
      switch (participantType) {
        case ParticipantType.Active:
          result = this.$t('active');
          break;
        case ParticipantType.Passive:
          result = this.$t('passive');
          break;
        case ParticipantType.Monitor:
          result = this.$t('monitor');
          break;
        case ParticipantType.Supervisor:
          result = this.$t('supervisor');
          break;
      }

      return result;
    },
    getInto() {
      _addToWaitingRoom({
        userId: this.profile.id,
        waitingRoomId: this.value.id,
        participantType: ParticipantType.Monitor,
      })
        .then(() => {
          this.$router.push({ name: 'turnsManager' });
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .dropdown-menu {
  z-index: 10;
}
.card.search-result {
  border: 0 !important;
  border-radius: 12px !important;
  border-left: 35px solid transparent !important;
  .action-options {
    position: absolute;
    right: 5px;
    top: 40px;
  }
  .button-expand {
    width: 35px;
    height: 100%;
    background: transparent;
    position: absolute;
    left: -35px;
    top: 0;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    box-shadow: 10px 0px 20px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 18px;
    }
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .subtitle {
    font-weight: bold;
  }
  .cell {
    label,
    span {
      font-size: 14px !important;
      color: #1c1d21 !important;
    }
    span,
    .comments {
      font-weight: normal;
    }
    i {
      font-size: 20px;
      margin-right: 8px;
      color: #707070;
    }
  }
  .minute-box {
    padding: 7px 25px;
    border-radius: 8px;
    &.green {
      background-color: #d9f5d7;
      color: #198e0f !important;
    }
    &.red {
      background-color: #fde7e7;
      color: #d53030 !important;
    }
  }
  &.search-result-red {
    background-color: #fdf5f5;
    .button-expand {
      background-color: #fdf5f5;
      i {
        color: #fad4d4;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #fad4d4 !important;
    }
    &.expand {
      .button-expand {
        background-color: #fad4d4;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-red {
    background-color: #fdf5f5;
    border-left: 0 !important;
    .box {
      border-radius: 10px;
      background-color: white;
    }
  }
  &.yellow {
    background-color: #fff7e2;
    .button-expand {
      background-color: #fff7e2;
      i {
        color: #f8e1a2;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #f8e1a2 !important;
    }
    &.expand {
      .button-expand {
        background-color: #f8e1a2;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-yellow {
    background-color: #fff7e2;
    border-left: 0 !important;
    .box {
      border-radius: 10px;
      background-color: white;
    }
  }
  &.grey {
    background-color: #f8f8f8;
    .button-expand {
      background-color: #f8f8f8;
      i {
        color: #d0cccc;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #d0cccc !important;
    }
    &.expand {
      .button-expand {
        background-color: #d0cccc;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-grey {
    background-color: #f8f8f8;
    border-left: 0 !important;
    .box {
      border-radius: 10px;
      background-color: white;
    }
  }
  &.lightgreen {
    background-color: #f2fff2;
    .button-expand {
      background-color: #f2fff2;
      i {
        color: #2eb782;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #2eb782 !important;
    }
    &.expand {
      .button-expand {
        background-color: #2db782;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-lightgreen {
    background-color: #f2fff2;
    border-left: 0 !important;
    .box {
      border-radius: 10px;
      background-color: white;
    }
  }
  &.purple {
    background-color: #e9daf6;
    .button-expand {
      background-color: #e9daf6;
      i {
        color: #a17fc6;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #a17fc6 !important;
    }
    &.expand {
      .button-expand {
        background-color: #a17fc6;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-purple {
    background-color: #e9daf6;
    border-left: 0 !important;
    .box {
      border-radius: 10px;
      background-color: white;
    }
  }
  &.white {
    border-top: 1px solid #d0cccc !important;
    border-bottom: 1px solid #d0cccc !important;
    border-right: 1px solid #d0cccc !important;
    .button-expand {
      border-left: 1px solid #d0cccc !important;
      i {
        color: #d0cccc;
      }
    }
    ::v-deep .c-actionButton {
      border: 1px solid #f8e1a2 !important;
    }
    &.expand {
      .button-expand {
        background-color: #d0cccc;
        i {
          color: white;
        }
      }
    }
  }
  &.expanded-white {
    border: 1px solid #d0cccc !important;
    .box {
      border-radius: 10px;
    }
  }
}
</style>
