<template>
  <div class="chat" >
    <div v-if="messages && messages.messages.length > 0" ref="chatContainer" class="chat-content">
      <div :key="i" v-for="(item, i) in messages.messages">
        <div
          class="message-content"
          :class="{
            mine: showUserMessage(i),
            'info-noti': isInfoNotification(i),
          }"
        >
          <div class="message">
            <div v-if="showFullName(i)" class="full-name">
              {{ item.userFullName }}
            </div>
            <div v-html="getTextMessage(item.message)" />
            <span class="time">{{ getTime(item.timestamp) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ConfirmationOptions from '@/constants/ConfirmationOptions';
import { mapGetters } from 'vuex';
import { modulesName } from '@/store';
import moment from 'moment';
import {
  getChatHistory as _getChatHistory,
} from '@/services/WaitingRoomService';

export default {
  name: 'ChatMessage',
  components: {
  },
  props: {
    waitingRoomId: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      collections: {
        confirmations: ConfirmationOptions,
        supervisors: [],

      },
      model: {
        evaluatorId: null,
        turnNumber: null,
        comment: null,
        referralId: null,
        fullComment: null,
        needSecondEvaluation: false
      },
      canResetService: false,
      loading: false,
      canEditEvaluation: false,
      messages: null,
      error: null,
      firstUserId: null
    };
  },
  async mounted() {
    await this.loadChatHistory();
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation', 'fullProfile']),
  },
  watch: {

  },
  methods: {
    async loadChatHistory() {
      await _getChatHistory(this.waitingRoomId)
        .then(({ data }) => {
          this.messages=data;
        });
    },
    showFullName(i) {
      const prevMessage = this.messages.messages[i - 1];
      if (!prevMessage) return true;
      return this.messages.messages[i].userId !== prevMessage.userId;
    },
    getTime(date) {
      return moment(date).format('hh:mm a');
    },
    isInfoNotification(i) {
      return this.messages.messages[i].message.startsWith('info-noti-');
    },
    getTextMessage(message) {
      let msg = message;
      const notifications = ['info-noti-citizen'];
      if (notifications.some((x) => msg.startsWith(x))) {
        notifications.forEach((noti) => {
          msg = msg.replaceAll(`${noti}-`, '');
        });
      }

      const mentions = message.mentions || [];

      for (const mention of mentions) {
        let label = '@';
        if (mention.username === 'all') {
          label += this.$t('all');
        } else if (mention.username === 'here') {
          label += this.$t('here');
        } else if (mention.name) {
          label += mention.name;
        } else {
          label += mention.username;
        }
        msg = msg.replaceAll(`@${mention.username}`, `<b>${label}</b>`);
      }

      return msg;
    },
    showUserMessage(i) {
      if(this.firstUserId == null) {
        this.firstUserId = this.messages.messages[i].userId;
        return true;
      } else {
        if(this.messages.messages[i].userId === this.firstUserId) {
          return true;
        }
      }
      return false;
    }
  },
};
</script>
<style>
.bold {
  font-weight: bold;
}
.bold-left {
  float: left;
  font-weight: bold;
}
.center {
  text-align: center;
}

.mention-item,
.mention-no-result {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: #ff8106;
}
</style>

<style scoped lang="scss">
.chat {
  .mention {
    .chat-content,
    .chat-form {
      border: 3px solid #e41e1e;
      border-radius: 4px;
    }
  }

  .chat-content {
    background-color: #F1F1F1;
    max-height: 300px;
    padding: 10px 10px;
    overflow-y: auto;
    border-radius: 8px;

    .message-content {
      padding-right: 20px;

      .message {
        border-radius: 8px;
        background-color: #feeec5;
        color: #1c1d21;
        padding: 12px;
        padding-bottom: 14px;
        margin-bottom: 8px;
        width: fit-content;
        font-size: 16px;
        position: relative;
        white-space: pre-line;
        min-width: 80px;
        .full-name {
          font-weight: bold;
          font-size: 12px;
        }
        .time {
          font-size: 10px;
          position: absolute;
          bottom: 2px;
          right: 12px;
        }
      }

      &.mine {
        padding-left: 20px;
        padding-right: 0px;
        display: flex;
        justify-content: end;
        .message {
          background-color: #e7fbd9;
        }
      }
      &.small {
        .message {
          font-size: 12px;
          padding: 10px;
          opacity: 0.7;

          .full-name {
            display: none;
          }
          .time {
            font-size: 8px;
          }
        }
      }
      &.info-noti {
        .message {
          font-size: 12px;
          padding: 10px;
          opacity: 0.7;
        }
        .full-name {
          display: none;
        }
        .time {
          font-size: 8px;
        }
      }
    }
  }
  .chat-form {
    background-color: #ebebeb;
    height: 120px;
    .mentionable {
      width: 100%;
      .textarea {
        display: block;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        resize: both;
        min-height: 20px;
        max-height: 110px;
        line-height: 20px;
        background-color: white;
        border-radius: 5px;
        padding: 8px;
        font-size: 18px;
        color: #1c1d21;
        resize: none;
        border: none;
      }
    }
  }
}
</style>
