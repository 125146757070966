<template>
  <div class="content">
    <h1 :data-text="`${$t('loading')}...`">
      {{ $t('loading') }}...
    </h1>
  </div>
</template>

<script>
export default {
  name: 'BigLoader',
};
</script>
<style scoped>
.content {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
h1 {
  position: relative;
  color: #333;
  font-size: 4em;
  letter-spacing: 5px;
  border-bottom: 16px solid #333;
  line-height: 1.4;
  font-family: consolas;
  text-transform: uppercase;
}
h1::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #FF8105;
  border-bottom: 16px solid #FF8105;
  animation: lod 2s linear infinite;
  overflow: hidden;
}
@keyframes lod {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
</style>
