<template>
  <section>
    <content-header v-if="showMaintenance" :title="$t('sessionList')" />
    <div v-if="showMaintenance" class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('sessionFilters')" />
        </div>
        <validation-observer ref="formValidator" novalidate>
          <div class="row">
            <div class="col-md-4 col-lg-3">
              <base-datetime-picker
                before
                format="dd/MMM/yyyy"
                set-initial-value
                :title="$t('fromDate')"
                type="date"
                :value="selectedFilters.dateFrom"
                @change="selectedFilters.dateFrom = $event"
              />
            </div>
            <div class="col-md-4 col-lg-3">
              <base-datetime-picker
                before
                format="dd/MMM/yyyy"
                set-initial-value
                :title="$t('toDate')"
                type="date"
                :value="selectedFilters.dateTo"
                @change="selectedFilters.dateTo = $event"
              />
            </div>
            <div class="col-md-4 col-lg-3">
              <filter-select
                v-model="selectedFilters.type"
                :label="$t('type')"
                :options="filters.types"
              />
            </div>
            <div class="col-md-4 col-lg-3">
              <filter-select
                v-model="selectedFilters.status"
                :label="$t('status')"
                :options="filters.status"
              />
            </div>
            <div v-if="filters.categories.length" class="col-md-4 col-lg-3">
              <filter-select
                v-model="selectedFilters.categoryId"
                fieldtext="name"
                fieldvalue="id"
                :label="categoryName"
                :options="filters.categories"
              />
            </div>
            <div class="col text-right pt-4">
              <button class="btn btn-primary btn-lg" @click="search()">
                <i class="far fa-search" /> {{ $t('search') }}
              </button>
            </div>
          </div>
        </validation-observer>
      </div>
      <separator class="my-4" :text="$t('sessionList')" />
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <custom-modal v-model="showEvaluationModal" :title="selectedTurn && selectedTurn.isCall ? $t('recordingDetail') : $t('chatDetail')">
      <EvaluationForm
        v-if="selectedTurn"
        :waiting-room-id = "selectedTurn.id"
        :turn-id = "selectedTurn.turnNumber"
        :turn-date = "selectedTurn.arrivalDate"
        :is-call = "selectedTurn.isCall"
        @close="closeEvaluationModal()"
      />
    </custom-modal>
    <custom-modal v-model="showPlayRecordingModal" :title="$t('recording')" size="md">
    </custom-modal>
      <div class="row">
        <div
          v-for="(session, index) in result.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :value="session"
            :special-field-configuration="specialFieldConfiguration"
            @edit="openEvaluationModal(session)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="result.count > 0"
        id="pgPager"
        class="my-4"
        :data="result"
        :page-size="pageSize"
        @pagination-go-page="getSessions($event)"
        @pagination-rows-per-page="getSessions(1, $event)"
      />
    </div>
    <BigLoader v-if="loading" />
  </section>
</template>
<script>
import CustomPagination from '@/components/CustomPagination';
import Separator from '@/components/Separator';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import CustomModal from '@/components/basics/modal/CustomModal';
import SearchResult from './components/SearchResult';
import { getSpecialFieldConfiguration as _getSpecialFieldConfiguration } from '@/services/CompanyService';
import { searchQualityEvaluation as _searchQualityEvaluation } from '@/services/WaitingRoomService';
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';
import BigLoader from '@/components/BigLoader';
import EvaluationForm from './components/EvaluationForm';

export default {
  name: 'QualityEvaluation',
  components: {
    Separator,
    contentHeader,
    SubHeader,
    CustomNotFound,
    CustomPagination,
    SearchResult,
    BigLoader,
    EvaluationForm,
    CustomModal
  },
  data() {
    return {
      filters: {
        companies: [],
        locations: [],
        types: [],
        status: [],
        categories: [],
      },
      selectedFilters: {
        dateFrom: null,
        dateTo: this.$moment().format(),
        type: null,
        status: null,
        categoryId: null,
      },
      categoryName: '',
      result: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      autoSearchInterval: null,
      loading: false,
      showMaintenance: true,
      showEvaluationModal: false,
      showPlayRecordingModal: false,
      selectedTurn: null,
    };
  },
  async created() {
    this.filters.types = [
      { value: 4, text: this.$t('chat') },
      { value: 2, text: this.$t('ring') },
    ];
    this.filters.status = [
      { value: 1, text: `${this.$t('NotPresent')} (${this.$t('unfinished')})` },
    ];
    await this.getConfiguration();
    this.search();
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, [
      'currentActiveLocation',
      'fullProfile',
    ]),
  },
  watch: {
    currentActiveLocation() {
      this.showMaintenance = false;
      this.loading = true;
      this.noResultsFound = false;
      setTimeout(async() => {
        await this.getConfiguration();
        this.selectedFilters = {
          dateFrom: null,
          dateTo: this.$moment().format(),
          type: null,
          status: null,
          categoryId: null,
        };
        this.result = {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        };
        this.loading = false;
        this.showMaintenance = true;
        this.search();
      }, 800);
    },
  },
  methods: {
    async getConfiguration() {
      await _getSpecialFieldConfiguration(this.fullProfile.companyId).then(
        ({ data }) => {
          this.categoryName = data.categoryName || '';
          this.filters.categories = data.categories;
          this.selectedFilters.categoryId = null;
          this.specialFieldConfiguration = data;
        },
      );
    },
    async search(autoSearch = false) {
      if (!autoSearch) {
        clearTimeout(this.autoSearchInterval);
        await this.getSessions(1);
      }
      this.autoSearchInterval = setTimeout(async () => {
        await this.getSessions();
        this.search(true);
      }, 60000);
    },
    async getSessions(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        ...this.selectedFilters,
        locationConfigurationId: Number(
          this.currentActiveLocation.locationConfigurationId,
        ),
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _searchQualityEvaluation(payload)
        .then(({ data }) => {
          this.result = data;
          this.noResultsFound = !this.result.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    openEvaluationModal(turn) {
      this.selectedTurn = turn;
      this.showEvaluationModal = true;
    },
    closeEvaluationModal() {
      this.showEvaluationModal = false;
    },
  },
  destroyed() {
    clearTimeout(this.autoSearchInterval);
  },
};
</script>
