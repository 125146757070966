<template>
  <b-container fluid>
    <b-row>
      <b-col
        cols="12"
        lg="6"
      >
        <div class="header-title py-3">
          <h1 class="title">
            {{ title }}
          </h1>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.header-title h1.title {
  text-align: left;
  letter-spacing: 0px;
  color: $color-font-primary;
  opacity: 1;
  font-size: 24px;
  font-weight: bold;
}
</style>
