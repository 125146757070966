<template>
  <section>
    <button
      class="btn btn-outline-primary float-right mr-3 mt-3"
      @click="getWaitingRoom(1)"
    >
      <i class="fas fa-redo" /> {{ $t('refresh') }}
    </button>
    <content-header :title="$t('monitoringActiveSessions')" />

    <div class="container-fluid">
      <separator class="my-4" :text="$t('listOfActiveSessions')" />
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="waitingRoom in waitingRooms.data"
          :key="waitingRoom.id"
          class="mt-3 col-12"
        >
          <search-result :value="waitingRoom" :special-field-configuration="specialFieldConfiguration" />
        </div>
      </div>
      <custom-pagination
        v-if="waitingRooms.count > 0"
        id="pgPager"
        class="my-4"
        :data="waitingRooms"
        :page-size="pageSize"
        @pagination-go-page="getWaitingRoom($event)"
        @pagination-rows-per-page="getWaitingRoom(pageIndex, $event)"
      />
    </div>
  </section>
</template>
<script>
import SearchResult from './components/SearchResult';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import CustomPagination from '@/components/CustomPagination';
import Separator from '@/components/Separator';
import contentHeader from '@/components/Header';
import { searchActive as _searchActive } from '@/services/WaitingRoomService';
import { modulesName } from '@/store';
import { mapGetters } from 'vuex';
import { getSpecialFieldConfiguration as _getSpecialFieldConfiguration } from '@/services/CompanyService';

export default {
  name: 'Monitor',
  components: {
    Separator,
    contentHeader,
    SearchResult,
    CustomPagination,
    CustomNotFound,
  },
  data() {
    return {
      waitingRooms: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      autoSearchInterval: null,
      specialFieldConfiguration: {},
    };
  },
  async mounted() {
    await _getSpecialFieldConfiguration(this.fullProfile.companyId).then(
      ({ data }) => {
        this.specialFieldConfiguration = data;
      },
    );

    this.search();
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, [
      'currentActiveLocation',
      'fullProfile',
    ]),
  },
  watch: {
    currentActiveLocation(curr, prev) {
      if (prev && curr && curr.locationConfigurationId == prev.locationConfigurationId) return;
      clearTimeout(this.autoSearchInterval);
      this.waitingRooms = {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      };
      this.noResultsFound = false;
      this.search();
    }
  },
  methods: {
    async search(autoSearch = false) {
      if (!autoSearch) {
        clearTimeout(this.autoSearchInterval);
        await this.getWaitingRoom(1);
      }
      this.autoSearchInterval = setTimeout(async () => {
        await this.getWaitingRoom();
        this.search(true);
      }, 60000);
    },
    async getWaitingRoom(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        locationConfigurationId:
          this.currentActiveLocation.locationConfigurationId,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
      };

      await _searchActive(payload).then(({ data }) => {
        this.waitingRooms = data;
        this.noResultsFound = !this.waitingRooms.count;
      });
    },
  },
  beforeDestroy() {
    clearTimeout(this.autoSearchInterval);
  },
};
</script>
