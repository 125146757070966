<template>
  <validation-observer ref="form" novalidate>
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-6"><label class="ml-4 bold-left">{{ $t('turn') }}</label>: <label>{{turnId}} </label></div>
          <div class="col-md-6"><label class="bold-left">{{ $t('date') }}</label>: <label>{{formatDate(turnDate)}} </label></div>
          <div class="col-md-12">
            <PlayRecording
            v-if="isCall"
            :waiting-room-id = "waitingRoomId"
            />
            <ChatMessage
            v-if="!isCall"
            :waiting-room-id = "waitingRoomId"
            />
          </div>          
        </div>
      </div>
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-5">
            <label style="color: #707070; float: left;">{{ $t('needSecondEvaluation') }}: </label>
            <b-form-checkbox
            style="margin-left: 10px; float: left"
              :disabled="!canEditEvaluation"
              v-model="model.needSecondEvaluation"
              switch
            >
            </b-form-checkbox>
          </div>
          <div class="col-md-7">
            <filter-select
              v-model="model.referralId"
              fieldtext="fullName"
              fieldvalue="userId"
              :label="$t('refferEvaluationTo')"
              :options="collections.supervisors"
              :disabled="!canEditEvaluation"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <validation-provider v-slot="{ errors }" rules="max:1024">
              <base-input
                v-model="model.comment"
                :error="errors[0]"
                :error-msg="$t('invalidField')"
                :label="$t('comment')"
                :max-length="1024"
                :textarea="true"
                :show-popover-title="model.fullComment != null"
                :show-popover-content="model.fullComment != null"
                :disabled="!canEditEvaluation"
                :rows="7"
                >
                <template #popoverContent>
                  <div v-if="model.fullComment != null" class="row px-3">
                    <div class="col-md-12">
                      <b>{{ model.fullComment }}</b>
                    </div>
                  </div>
                  
                </template>
              </base-input>
            </validation-provider>
          </div>
          <div class="col-md-12">
            <label class="left">{{ $t('lastUpdate') }}</label>: <label>{{ formatDate(model.modifyDate) }} </label>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <hr />
      </div>
      <div class="col-md-12 text-right">
        <base-filled-button
          class="mr-0"
          icon-class="far fa-save"
          :on-click="onSubmit"
          :text="$t('save')"
          :disabled="!canEditEvaluation"
        />
      </div>
    </div>
  </validation-observer>
</template>

<script>
import ConfirmationOptions from '@/constants/ConfirmationOptions';
import BaseInput from '@/components/BaseInput.vue';
import { mapGetters } from 'vuex';
import { modulesName } from '@/store';
import PlayRecording from './PlayRecording';
import ChatMessage from './ChatMessage';
import moment from 'moment';

import {
  getEvaluationToUpdate as _getEvaluationToUpdate,
  saveEvaluation as _saveEvaluation,
  getEvaluationReferrals as _getEvaluationReferrals,
} from '@/services/WaitingRoomService';

export default {
  name: 'EvaluationForm',
  components: {
    BaseInput,
    PlayRecording,
    ChatMessage
  },
  props: {
    waitingRoomId: {
      type: Number,
      default: null,
    },
    turnId: {
      type: Number,
      default: null,
    },
    turnDate: {
      type: String,
      default: null,
    },
    isCall: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      collections: {
        confirmations: ConfirmationOptions,
        supervisors: [],
      },
      model: {
        evaluatorId: null,
        turnNumber: null,
        comment: null,
        referralId: null,
        fullComment: null,
        needSecondEvaluation: false,
        arrivalDate: null,
        modifyDate: null
      },
      canResetService: false,
      loading: false,
      canEditEvaluation: false,
    };
  },
  async mounted() {
    
    this.$refs.form.reset();
    await this.loadReferrals();
    await this.loadEvaluation();
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation', 'fullProfile']),
  },
  watch: {
    
  },
  methods: {
    async loadReferrals() {
      this.loading = true;
      await _getEvaluationReferrals(this.waitingRoomId)
        .then(({ data }) => {
          this.collections.supervisors = data;
        })
        .finally(() => (this.loading = false));
    },
    async loadEvaluation() {
      this.loading = true;
      await _getEvaluationToUpdate(this.waitingRoomId)
        .then(({ data }) => {
          if(data) {
            this.model.referralId = data.referralId;
            this.model.evaluatorId = data.evaluatorId;
            this.model.needSecondEvaluation = data.needSecondEvaluation;
            this.model.modifyDate = data.modifyDate;
            this.canEditEvaluation = ((this.model.needSecondEvaluation == true) || (this.model.evaluatorId && this.model.evaluatorId === this.fullProfile.userId) || (this.model.referralId && this.model.referralId === this.fullProfile.userId))
            
            if(this.canEditEvaluation && (data.evaluatorId !== this.fullProfile.userId)) {
              this.model.fullComment = data.comment;
              this.model.comment = null;
            } else {
              this.model.comment = data.comment;
            }
          }
          else
          {
            this.canEditEvaluation = true;
          }
        })
        .finally(() => (this.loading = false));
        
    },
    async onSubmit() {
      this.loading = true;
      var payload = {
        waitingRoomId: this.waitingRoomId,
        comment: this.model.comment,
        evaluatorId: this.fullProfile.userId,
        needSecondEvaluation: this.model.needSecondEvaluation,
        referralId: this.model.referralId
      };

      await _saveEvaluation(payload)
        .then(() => {
          this.$emit('close');
          this.ShowSuccessSaveToast();
        })
        .catch((error) => {
          this.ShowErrorToast(error.response.data.message);
        })
        .finally(() => (this.loading = false));
    },
    formatDate(value) {
        if (moment(value).isValid()) {
          const formatedDate = moment(value).format('DD/MMM/YYYY');
          return formatedDate
        } else {
          return '';
        }
      }
  },
};
</script>
<style lang="scss" scoped>
.c-s-filterMultiSelect {
  height: 250px !important;
}
</style>